import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <header className="flex justify-between items-center p-4 bg-white text-black border-b-2 border-black">
    <div className="flex items-center">
      <img className="w-10 h-10 rounded-full border border-black mr-4" src="https://media.sketchfab.com/avatars/38f0e371434e4c52aefb9741f33e8766/704b023a690a4149b56874edf16d2572.png" alt="Profile" />
      <div className="font-bold text-xl md:text-2xl lg:text-2xl">
        <Link to="/">
          Gianluca Gatto
        </Link>
      </div>
    </div>
    <nav>
      <ul className="flex space-x-2 sm:space-x-4 text-sm md:text-base lg:text-base">
        <li className="hover:underline"><Link to="/">Portfolio</Link></li>
        <li className="hover:underline"><Link to="/contact">Contact</Link></li>
        <li className="hover:underline"><Link to="/about">About</Link></li>
      </ul>
    </nav>
  </header>
);

export default Header;
