import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchEntry, fetchEntries } from '../contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Loading = () => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    <p className="text-gray-700">Loading...</p>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="text-red-500 text-lg">
      <p>Error: {message}</p>
      <Link to="/" className="text-blue-500 hover:underline">Go back to the portfolio</Link>
    </div>
  </div>
);

const Artwork = () => {
  const { id } = useParams();
  const [artwork, setArtwork] = useState(null);
  const [embedHtml, setEmbedHtml] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextArtworkSlug, setNextArtworkSlug] = useState(null);

  useEffect(() => {
    const getArtwork = async () => {
      try {
        const fetchedArtwork = await fetchEntry(id);
        setArtwork(fetchedArtwork);

        const allArtworks = await fetchEntries();
        const currentArtworkIndex = allArtworks.findIndex(art => art.fields.slug === id);
        const nextArtworkIndex = (currentArtworkIndex + 1) % allArtworks.length;
        setNextArtworkSlug(allArtworks[nextArtworkIndex].fields.slug);

        if (fetchedArtwork.fields.sketchfabID) {
          try {
            const response = await fetch(`https://sketchfab.com/oembed?url=https://sketchfab.com/models/${fetchedArtwork.fields.sketchfabID}`);
            const data = await response.json();
            const updatedHtml = data.html.replace('<iframe', '<iframe class="w-full rounded-lg shadow-lg" style="aspect-ratio: 16 / 9;"');
            setEmbedHtml(updatedHtml);
          } catch (sketchfabError) {
            console.error('Error fetching Sketchfab data:', sketchfabError);
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getArtwork();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!artwork) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 md:px-8">
        <div className="bg-white rounded shadow-md p-4 md:p-8 w-full max-w-2xl text-center">
          <h1 className="text-2xl md:text-3xl mb-4 text-black">Artwork not found</h1>
          <Link to="/" className="text-blue-500 hover:underline">Go back to the portfolio</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex justify-between items-center mb-6">
          <Link to="/" className="bg-gray-200 hover:bg-gray-300 text-black text-sm font-semibold px-4 py-2 rounded-full shadow-sm">
            ← Back to Portfolio
          </Link>
          {nextArtworkSlug && (
            <Link to={`/artworks/${nextArtworkSlug}`} className="bg-gray-200 hover:bg-gray-300 text-black text-sm font-semibold px-4 py-2 rounded-full shadow-sm">
              Next Artwork →
            </Link>
          )}
        </div>
        <div className="bg-white rounded-lg shadow-lg p-6 md:p-8">
          <h1 className="text-3xl font-bold mb-6 text-gray-800">{artwork.fields.title}</h1>
          <div className="flex flex-wrap gap-4 justify-center mb-6">
            {artwork.fields.image && (
              <div className="w-full md:w-7/12 lg:w-6/12 xl:w-5/12 overflow-hidden rounded-lg shadow-lg">
                <img className="object-cover w-full h-full" src={artwork.fields.image.fields.file.url} alt={artwork.fields.title} />
              </div>
            )}
            {artwork.fields.images && artwork.fields.images.map((image, index) => (
              <div key={index} className="w-full md:w-7/12 lg:w-6/12 xl:w-5/12 overflow-hidden rounded-lg shadow-lg">
                <img className="object-cover w-full h-full" src={image.fields.file.url} alt={artwork.fields.title} />
              </div>
            ))}
          </div>
          <div className="text-gray-700 text-lg leading-relaxed bg-gray-50 rounded-lg shadow p-6 mb-6">
            {documentToReactComponents(artwork.fields.description)}
          </div>
          {embedHtml && (
            <div className="sketchfab-embed-wrapper w-full md:w-8/12 lg:w-7/12 xl:w-6/12 mx-auto">
              <div dangerouslySetInnerHTML={{ __html: embedHtml }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Artwork;
