import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchEntries } from '../contentfulClient'; // Ensure this path is correct

const Portfolio = () => {
  const [artworks, setArtworks] = useState([]);

  useEffect(() => {
    const getArtworks = async () => {
      const allArtworks = await fetchEntries();
      setArtworks(allArtworks);
    };

    getArtworks();
  }, []);

  return (
    <div className="flex items-start justify-center min-h-screen bg-gray-100 p-8">
      <div className="bg-white rounded shadow-md p-8 w-full md:w-4/5 lg:w-3/4 xl:w-2/3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {artworks.map((artwork, index) => (
            <Link to={`/artworks/${artwork.fields.slug}`} key={index}>
              <div className="relative pb-full">
                <img 
                  className="absolute top-0 left-0 w-full h-full object-cover shadow-2xl rounded-md transform hover:scale-110 transition-transform duration-300"
                  src={artwork.fields.image.fields.file.url}
                  alt={artwork.fields.title}
                  loading="lazy"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 text-center rounded-md">
                  <p className="text-white">{artwork.fields.title}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
