import React from 'react';

const About = () => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100 p-8">
    <div className="bg-white rounded shadow-md p-8 w-full max-w-lg text-center">
      <h1 className="text-2xl mb-4 text-black">About Gianluca Gatto</h1>
      <p className="text-black">
        Gianluca Gatto is a skilled 3D modeling specialist with an extensive portfolio of diverse 3D models. With proficiency in tools like Blender, AutoCAD, SketchUp, Photoshop, and Substance, Gianluca creates high-quality 3D models that range from everyday items like mugs and chairs to unique and detailed models like a Tokyo suburban rustic house and a Japanese vending machine.
      </p>
      <p className="mt-4 text-black">
        Since joining Sketchfab in November 2021, Gianluca's work has garnered over 5.2k views and 198 likes, demonstrating his ability to create models that appeal to a wide audience. His skills extend beyond 3D modeling to include photogrammetry, further broadening his expertise in the 3D field.
      </p>
      <p className="mt-4 text-black">
        Whether you're looking for a detailed model of a specific object or need assistance with a larger 3D project, Gianluca has the skills and experience to deliver high-quality results. For your 3D modeling needs, feel free to <a href="/contact" className="font-bold">contact</a> Gianluca.
      </p>
    </div>
  </div>
);

export default About;
