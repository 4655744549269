import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Portfolio from './components/portfolio';
import About from './components/about';
import Contact from './components/contact';
import Artwork from './components/artwork';
import Footer from './components/footer';

const App = () => (
  <Router>
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/artworks/:id" element={<Artwork />} />
          <Route path="/" element={<Portfolio />} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;