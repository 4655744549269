import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const fetchEntries = async () => {
  const entries = await client.getEntries({
    content_type: 'artwork', // Adjust this to match your content type ID
  });
  return entries.items;
};

export const fetchEntry = async (slug) => {
  const entries = await client.getEntries({
    content_type: 'artwork', // Adjust this to match your content type ID
    'fields.slug': slug,
  });
  return entries.items[0];
};
