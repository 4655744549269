import React from 'react';

const Footer = () => (
  <footer className="bg-white bg-opacity-90 text-black border-t-2 border-black p-4">
    <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
      <div className="text-sm mb-2 md:mb-0">
        © {new Date().getFullYear()} Gianluca Gatto. All rights reserved.
      </div>
      <div className="flex space-x-4">
        <a 
          href="https://sketchfab.com/shockproof" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="Sketchfab"
        >
          Sketchfab
        </a>
        <a 
          href="https://www.youtube.com/@gianlucagatto1" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="YouTube"
        >
          YouTube
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
