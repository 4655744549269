import React, { useState } from 'react';

const Contact = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch('https://contact-form-service-fu4jw3brrq-uc.a.run.app/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formState),
    })
      .then(() => {
        setSubmitted(true);
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  if (submitted) {
    return (
      <div className="flex items-start justify-center min-h-screen bg-gray-100 pt-8 px-4 md:p-8">
        <div className="bg-white rounded shadow-md p-8 w-full max-w-lg text-center">
          <h1 className="text-2xl mb-4 text-black">Thank you!</h1>
          <p>Your message has been successfully submitted. We will be in touch soon.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-start justify-center min-h-screen bg-gray-100 pt-8 px-4 md:p-8">
      <div className="bg-white rounded shadow-md p-8 w-full max-w-lg">
        <h1 className="text-2xl mb-4 text-black">Contact</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-black text-sm font-bold mb-2" htmlFor="name">
              Name
              <span className="text-gray-500 text-xs"> (Please enter your full name)</span>
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" name="name" value={formState.name} onChange={handleChange} required />
          </div>
          <div className="mb-4">
            <label className="block text-black text-sm font-bold mb-2" htmlFor="email">
              Email
              <span className="text-gray-500 text-xs"> (Please enter your email address for correspondence)</span>
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" name="email" value={formState.email} onChange={handleChange} required />
          </div>
          <div className="mb-4">
            <label className="block text-black text-sm font-bold mb-2" htmlFor="message">
              Message
              <span className="text-gray-500 text-xs"> (Please provide details about your request or inquiry)</span>
            </label>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" id="message" name="message" value={formState.message} onChange={handleChange} rows="4" required></textarea>
          </div>
          <div className="flex items-center justify-center">
            <button className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
